<template>
    <div class="brand-list" v-loading="loading">
        <div class="brand-head">
            <h2>
                <img src="@/assets/images/city-left.png"/>
                湖南地区
                <img src="@/assets/images/city-right.png"/>
            </h2>
            <router-link to="/promotion/redarea">
                <div class="changeCity">【返回湘赣红主页】</div>
            </router-link>
        </div>


        <!-- banner -->
        <div class="banner-box">
            <div class="banner">
                <el-carousel height="445px" v-if="bannerList.length>0">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                        <el-image :src="$img(item)" fit="cover" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="banner-info" v-if="bannerDetail">
                <p class="intro">{{bannerDetail.description}}</p>
                <div class="main-push">
                    <div class="item"
                         v-for="(item, index) in mainPush" :key="index"
                         @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>


        <div class="areaFilters">
            <div class="items" v-for="(item, index) in areaCitylist" :key="index" @click="changeArea(item)">{{item.name}}</div>
        </div>

        <div class="feed-tab-wrapper">
            <ul class="feed-tab" >
                <li class="feed-tab__item" :class="filters.category_id === 0? 'feed-tab__item--active' : ''" @click="selectedAllCategoryGoods()">
                    <div class="feed-tab__item-title">
                        <img v-if="filters.category_id === 0" src="@/assets/images/list-red.png" style="width:19px;height:16px;margin-right:5px;margin-top:-1px"/>
                        <img v-else src="@/assets/images/list.png"  style="width:19px;height:16px;margin-right:5px;margin-top:-1px" />
                        <span class="feed-tab__item-title-text">全部</span>
                    </div>
                </li>
                <li class="feed-tab__item" v-for="(item, index) in categoryList" :key="index" :class="filters.category_id === item.category_id ? 'feed-tab__item--active' : ''" @click="selectedCategoryGoods(item)">
                    <div class="feed-tab__item-title"><span class="feed-tab__item-title-text">{{item.category_name}}</span></div>
                </li>
            </ul>
        </div>

        <div class="list-wrap">
            <div class="cargo-list" v-if="cargoList.length">
                <div class="goods-info">
                    <div
                            class="item"
                            v-for="(item, index) in cargoList" :key="index"
                            @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                        <div class="goods-wrap">
                            <div class="img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
                                <img class="bgImg" src="@/assets/images/goods-redarea-bg.png"/>
                            </div>
                            <h3>{{ item.sku_name }}</h3>
                            <!--                            <p class="desc" v-if="item.des">{{ item.spec_name }}</p>-->
                            <p class="price">
                                <span class="num">￥{{ item.price }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination
                            background
                            :pager-count="5"
                            :total="total"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="currentPage"
                            :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange"
                            hide-on-single-page
                    ></el-pagination>
                </div>
            </div>
            <div class="empty" v-else>
                <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
            </div>
        </div>
    </div>

</template>

<script>
    import { getXghAreaDetail,getXghAreaList, getXghGoodsGood} from "@/api/goods/goods";
    import { getChildCategory } from "@/api/goods/goodscategory";

    export default {
        name: "list",
        components: {
        },
        inject:["reload"],
        computed: {},
        data: () => {
            return {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                categoryList:[],
                cargoList:[],
                bannerList:[],
                areaCitylist:[],
                mainPush:[],
                bannerDetail:{},
                filters: {
                    area_id: 430000000000,
                    area_type:'city',
                    category_id: 0,
                    website_id: 0,
                    site_id: 0,
                    website_type: 0,
                    type_id:0,
                },
                loading: true,
            }
        },
        created() {

        },
        mounted() {
            this.getCategory() //菜单
            this.getXghAreaList() //湖南地区
        },
        methods: {
            selectedCategoryGoods(item){
                this.filters.category_id = item.category_id
                this.currentPage =1
                this.getXghGoodsGoodList() //产品列表
            },
            selectedAllCategoryGoods(){
                this.filters.category_id = 0
                this.currentPage =1
                this.getXghGoodsGoodList() //产品列表
            },
            getCategory(){
                getChildCategory({
                    level: 1,
                    pid: 0
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        // console.log('分类数据', res.data)
                        this.categoryList = res.data
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
            },
            getXghAreaList(){
                getXghAreaList({
                    type: 1
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        // console.log('分类数据', res.data)
                        this.areaCitylist = res.data
                        if(res.data.length>0) {
                            this.bannerDetail = res.data[0]
                            this.bannerList = res.data[0].imgs
                            this.mainPush = res.data[0].label_data
                            this.filters.area_type = res.data[0].area_type
                            this.filters.area_id = res.data[0][res.data[0].area_type]
                        }
                        this.getXghGoodsGoodList()
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
            },
            getXghGoodsGoodList() {
                const params = {
                    page: this.currentPage,
                    page_size: this.pageSize,
                    ...this.filters
                }
                getXghGoodsGood(params || {})
                    .then(res => {
                        // console.log('商品列表',res);
                        const { count, page_count, list } = res.data
                        this.total = count
                        this.cargoList = list
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            changeArea(item){
                getXghAreaDetail({id:item.id})
                    .then(res => {
                        this.bannerDetail = res.data
                        if(res.data.imgs) {
                            this.bannerList = res.data.imgs
                            this.mainPush = res.data.label_data
                            this.filters.area_type = res.data.area_type
                            this.filters.area_id = res.data[res.data.area_type]
                            this.getXghGoodsGoodList()
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            handlePageSizeChange(size) {
                this.pageSize = size
                this.currentPage =1
                this.getXghGoodsGoodList()
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getXghGoodsGoodList()
            },
            imageError(index) {
                this.cargoList[index].sku_image = this.defaultGoodsImage;
            }
        }
    };
</script>

<style lang="scss">
    #app{
        background-color: #f7f7f7;
    }

    .el-carousel__arrow--left{left:0}
    .el-carousel__arrow--right{right:0}

    .brand-head{
        width: 1210px;
        height:60px;
        margin: 20px auto 10px;
        text-align: center;
        position:relative;
        h2{
            font-size: 36px;
            color: #D81E01;
            img{margin: 0px 10px;}
        }
        .changeCity{
            position: absolute;
            width: 150px;
            height:20px;
            right:0;
            bottom:0;
            text-align: right;
            cursor: pointer;
        }
        .changeCity:hover{
            color: #d81e01;
        }
    }

    .banner-box{
        width: 1210px;
        background: #fff;
        margin: 0px auto;
        padding: 0;
        height: 445px;
        border-radius: 8px;
        .banner-info{
            width: 510px;
            height:445px;
            overflow: hidden;
            float: left;
            color: #301D1D;
            padding-bottom: 20px;
            overflow: hidden;
            .intro{
                height: 170px;
                text-indent:40px;
                font-size: 15px;
                line-height: 24px;
                margin: 30px 30px 10px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
            }
            .main-push{
                margin: 0 10px 0 15px;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                .item{
                    width: 45%;
                    height:42px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    line-height: 42px;
                    font-size: 15px;
                    text-align: center;
                    border:1px solid #eee;
                    padding: 0px 10px;
                    cursor: pointer;
                }
                .item:nth-child(2n+0){
                    margin-left:-1px;
                    margin-top:-1px;
                }
                .item:nth-child(1n+0){
                    margin-top:-1px;
                }
            }
        }
        .banner{
            width: 700px;
            height:445px;
            position: relative;
            float: left;
            img{
                height:445px;
                width: 700px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
    }

    .areaFilters{
        width: 1176px;
        background: #fff;
        margin: 20px auto;
        padding: 8px 17px;
        position: relative;
        border-radius: 8px;
        .items {
            background: url("../../../../assets/images/redarea-bg.png") no-repeat center center;
            display: inline-block;
            padding: 0;
            margin: 10px;
            cursor: pointer;
            font-size: 20px;
            line-height: 80px;
            width: 148px;
            height: 80px;
            color:#fff;
            text-align: center;
            font-family: PingFangSC-Semibold, PingFang SC;
            cursor: pointer;
        }

    }

    .feed-tab-wrapper{
        width: 1170px;
        padding:0px 20px;
        background-color:#D81E01 ;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height:48px;
        .feed-tab {
            width: 1170px;
            margin: 0 auto;
            height: 48px;
            display: flex;
            .feed-tab__item {
                position: relative;
                height: 42px;
                margin-top:6px;
                width: 234px;
                float: left;
                text-align: center;
                cursor: pointer;
                .feed-tab__item-title {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 42px;
                }
                .feed-tab__item-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
            .feed-tab__item.feed-tab__item--active{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #fff;
                .feed-tab__item-title {
                    color: #D81E01;
                    font-size: 17px;
                }
            }
        }
    }

    .list-wrap {
        width: 1190px;
        overflow: hidden;
        min-height: 500px;
        background-color:#fff;
        padding: 10px 10px 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 30px;
        .goods-info {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 20%;
                margin: 0;
                background: #fff;
                cursor: pointer;
                padding:0;
                transition: all 0.2s linear;
                .goods-wrap{
                    margin: 0 11px 20px;
                    padding: 0px 0px 10px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    height:300px;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .img-wrap {
                        width: 216px;
                        height: 216px;
                        margin: 0px auto;
                        text-align: center;
                        overflow: hidden;
                        position:relative;
                        img {
                            width: 216px;
                            height: 216px;
                            z-index: 1;
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        }
                    }
                    h3 {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        height: 44px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 10px 10px 5px;
                    }
                    .desc {
                        margin:0 10px;
                        height:14px;
                        font-size: 13px;
                        line-height:14px;
                        color: #b0b0b0;
                        text-align: left;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .price {
                        margin:0 10px;
                        font-size: 16px;
                        text-align: left;
                        font-weight: 500;
                        color: $base-color;
                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }
                }
            }
        }
    }
    .empty {
        margin-top: 45px;
    }
    .pager {
        text-align: center;
        margin: 15px 0;
    }
</style>
